import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from './redux';
import './style/css/destyle.css';
import './style/css/main.css';

// Function to detect and handle blank page
// function detectBlankPage() {
//     const rootElement = document.getElementById('root');

//     // Check if the root element is empty or not rendered properly
//     if (!rootElement || rootElement.innerHTML.trim() === '') {
//         console.warn('Blank page detected. Clearing local storage...');

//         // Ensure the reload only happens once per session
//         if (!sessionStorage.getItem('reloadedOnce')) {
//             console.warn('Reloading for the first time...');

//             // Save tokens temporarily
//             const token = localStorage.getItem('accessToken');
//             const refreshToken = localStorage.getItem('refreshToken');
            
//             localStorage.clear();

//             // Restore the tokens after clearing storage
//             if (token) localStorage.setItem('accessToken', token);
//             if (refreshToken) localStorage.setItem('refreshToken', refreshToken);

//             // Mark reload has occurred in session storage
//             sessionStorage.setItem('reloadedOnce', 'true');

//             window.location.reload();
//         } else {
//             console.warn('Page already reloaded once. Skipping further reloads.');
//         }
//     }
// }

// setTimeout(detectBlankPage, 3000);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
        <App />
    </Provider>
);

reportWebVitals();
