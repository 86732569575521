import { useEffect } from 'react';

function SearchResults({ data }) {
    // Search component
    useEffect(() => {
        const handleInput = (searchInput) => {
            const searchBlock = searchInput.closest('.search-block');
        
            if (searchInput.value.trim() !== '') {
                searchBlock?.classList.add('active');
            } else {
                searchBlock?.classList.remove('active');
            }
        };
    
        const searchInputs = document.querySelectorAll('.searchInput');
    
        searchInputs.forEach((searchInput) => {
            searchInput.addEventListener('input', () => handleInput(searchInput));
        });
    
        // Cleanup function
        return () => {
            searchInputs.forEach((searchInput) => {
                searchInput.removeEventListener('input', () => handleInput(searchInput));
            });
        };
    }, []);
    return (
        data && data.length > 0 &&
        <div className="search-results d-flex">
            {data?.map((item) => (
                <div className="suggestion" key={item.id}>
                    <div className="image">
                        <a href={`/catalog/product/${item.slug}?id=${item.id}&category=${item.categories[item.categories.length - 1].id}`}>
                            <img src={item.img} alt="product" />
                        </a>
                    </div>
                    <div className="desc">
                        <div className="title">
                            <a href={`/catalog/product/${item.slug}?id=${item.id}&category=${item.categories[item.categories.length - 1].id}`}>{item.name}</a>
                        </div>
                        <div className="category">
                            <a href={`/catalog/product/${item.slug}?id=${item.id}&category=${item.categories[item.categories.length - 1].id}`}>
                                {`${item.categories[0].name}`} &gt; {`${item.categories[item.categories.length -1].name}`}
                            </a>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default SearchResults;
