import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import StaticDataService from '../../services/StaticDataService';
import { extractError } from '../../helpers/api';

const model = {
    homePage: null,
    mainPage: null,
    aboutPage: null,
    solutionsPage: null,
    faqsPage: null,
    technicalDataPage: null,
    contactPage: null,
    catalogModal: null,
    productsBySectorModal: null,
    catalogPage: null,
    privacyPage: null,
    termsAndConditionsPage: null,
    literaturePage: null,
    crossReferencePage: null,
    certificationsPage: null,
    newsPage: null,
    loading: false,
    error: null,
};

export const getStaticData = createAsyncThunk(
    'staticData/getStaticData',
    async (_, thunkApi) => {
        try {
            const response = await StaticDataService.getStaticData();
            return response.data;
        } catch (error) {
            return thunkApi.rejectWithValue(extractError(error));
        }
    },
);

const staticDataSlice = createSlice({
    name: 'staticData',
    initialState: model,
    reducers: {},
    extraReducers: (builder) => {
        // get static data
        builder.addCase(getStaticData.pending, (state) => {
            state.loading = true;
        }).addCase(getStaticData.fulfilled, (state, action) => {
            state.loading = false;
            state.homePage = action.payload.home_page;
            state.mainPage = action.payload.main_page;
            state.aboutPage = action.payload.about_page;
            state.solutionsPage = action.payload.solutions_page;
            state.faqsPage = action.payload.faqs_page;
            state.technicalDataPage = action.payload.technical_data_page;
            state.contactPage = action.payload.contact_page;
            state.catalogModal = action.payload.catalog_modal;
            state.productsBySectorModal = action.payload.products_by_sector_modal;
            state.catalogPage = action.payload.catalog_page;
            state.privacyPage = action.payload.privacy_page;
            state.termsAndConditionsPage = action.payload.terms_and_conditions_page;
            state.literaturePage = action.payload.literature_page;
            state.crossReferencePage = action.payload.cross_reference_page;
            state.certificationsPage = action.payload.certifications_page;
            state.newsPage = action.payload.news_page;
        }).addCase(getStaticData.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        }); 
    },
});

export default staticDataSlice.reducer;
