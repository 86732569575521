import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import ReactHtmlParser from 'react-html-parser';
import { LogoIcon } from '../../../assets';
import { SearchIcon, UserLightMdIcon } from '../../../assets/icons';

function Footer() {
    const currentPath = window.location.pathname;
    const footerMenu = useSelector((state) => state.menu.footerMenu);
    const certifications = useSelector(state => state.dynamicData.certifications);
    const mainData = useSelector(state => state.staticData.mainPage);
    const footerData = mainData?.footer_section;

    const [title_part1, title_part2] =
        footerData?.certifications?.title ? footerData.certifications.title.split('-') : [];
    const [part1, part2, part3, part4] =
        footerData?.haydon_logo_copyright?.description ? footerData.haydon_logo_copyright.description.split('-') : [];

    useEffect(() => {
        document.getElementById("footer-current-year").innerHTML = new Date().getFullYear();
    }, []);

    return (
        <section className="footer bg-dark">
            <div className="container">
                <div className="bg-logo">
                    <LogoIcon />
                </div>
                {currentPath !== '/about' && (
                    <div className="certifications d-flex">
                        <div className="left">
                            <div className="title">
                                {ReactHtmlParser(title_part1)}
                                {ReactHtmlParser(title_part2)}
                            </div>
                            <div className="text">{ReactHtmlParser(footerData?.certifications.description)}</div>
                        </div>
                        <div className="right d-flex">
                            {certifications?.map((certification) => (
                                (certification.display === 'B' || certification.display === 'H') &&
                                    <div key={certification.id} className="logo">
                                        <a href={certification.button_link} target="_blank"><img src={certification.img_url_1} alt={`certification-${certification.id}`} /></a>
                                    </div>
                            ))}
                        </div>
                    </div>
                )}
                <div className="footer-content d-flex">
                    <div className="mobile-logo">
                        <a href="/"><img src={footerData?.haydon_logo_copyright.img_url} alt="haydon-logo" /></a>
                    </div>
                    <div className="col">
                        <div className="logo">
                            <a href="/">
                                <img src={footerData?.haydon_logo_copyright.img_url} alt="haydon-logo" />
                            </a>
                        </div>
                        <a href="/privacy" className="legal-link">
                            {ReactHtmlParser(part1)}
                        </a>
                        <a href="/terms-of-use" className="legal-link">
                            {ReactHtmlParser(part2)}
                        </a>
                        <div className="copyright">
                            {ReactHtmlParser(part3)}
                            {' '}
                            <span id="footer-current-year"></span>
                            {ReactHtmlParser(part4)}
                        </div>
                    </div>
                    {footerMenu?.map((item) => (
                        <div className="col" key={item.id}>
                            <div className="title">{item.menu_name}</div>
                            <ul>
                                {item.children.map((child, i) => (
                                    child.key !== 'request-a-quote' &&
                                    <li key={i}><a href={child.menu_link}>{child.menu_name}</a></li>
                                ))}
                            </ul>
                        </div>
                    ))}
                    <div className="col">
                        {/* <button className="login outline-btn style-2">
                            <a>
                                <UserLightMdIcon />
                                <span>Login</span>
                            </a>
                        </button> */}
                        <button className="search outline-btn search-button">
                            <a>
                                <SearchIcon />
                                <span>Search</span>
                            </a>
                        </button>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Footer;