import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { extractError } from '../../../helpers/api';
import {
    setOtpToken,
    setResetToken,
} from '../../../helpers/handleToken';
import ResetPasswordSlice from '../../../services/AuthServices/ResetPasswordService';

const model = {
    userEmail: '',
    submitting: false,
    step: 0,
    resetPasswordSuccess: false,
    error: null,
    resendMessageSuccess: '',
};

export const resetPasswordFirstStep = createAsyncThunk(
    'resetPassword/resetPasswordFirstStep',
    async (data, thunkApi) => {
        try {
            const response = await ResetPasswordSlice.resetPasswordFirstStep(data);
            const {
                otp_token: otpToken,
            } = response.data;
            setOtpToken(otpToken);
            return response.data;
        } catch (error) {
            return thunkApi.rejectWithValue(extractError(error));
        }
    },
);

export const resetPasswordSecondStep = createAsyncThunk(
    'resetPassword/resetPasswordSecondStep',
    async (data, thunkApi) => {
        try {
            const response = await ResetPasswordSlice.resetPasswordSecondStep(data);
            const {
                reset_token: resetToken,
            } = response.data;
            setResetToken(resetToken);
            return response.data;
        } catch (error) {
            return thunkApi.rejectWithValue(extractError(error));
        }
    },
);

export const resetPasswordThirdStep = createAsyncThunk(
    'resetPassword/resetPasswordThirdStep',
    async (data, thunkApi) => {
        try {
            const response = await ResetPasswordSlice.resetPasswordThirdStep(data);
            return response.data;
        } catch (error) {
            return thunkApi.rejectWithValue(extractError(error));
        }
    },
);

export const resetPasswordResendOtp = createAsyncThunk(
    'resetPassword/resetPasswordResendOtp',
    async (data, thunkApi) => {
        try {
            const response = await ResetPasswordSlice.resetPasswordResendOtp(data);
            const { otp_token: otpToken } = response.data;
            setOtpToken(otpToken);
            return response.data;
        } catch (error) {
            return thunkApi.rejectWithValue(extractError(error));
        }
    },
);

const resetPasswordSlice = createSlice({
    name: 'resetPassword',
    initialState: model,
    reducers: {
        clearResetPasswordErrorState: (state) => {
            state.error = null;
        },
        clearResetPasswordResendMessageSuccess: (state) => {
            state.resendMessageSuccess = '';
        },
        clearResetPasswordSuccessState: (state) => {
            state.resetPasswordSuccess = false;
        },
    },
    extraReducers: (builder) => {
        // reset password first step
        builder.addCase(resetPasswordFirstStep.pending, (state) => {
            state.submitting = true;
        }).addCase(resetPasswordFirstStep.fulfilled, (state, action) => {
            state.submitting = false;
            state.step = 1;
            state.userEmail = action.payload.email;
        }).addCase(resetPasswordFirstStep.rejected, (state, action) => {
            state.submitting = false;
            state.error = action.payload;
        });

        // reset password second step
        builder.addCase(resetPasswordSecondStep.pending, (state) => {
            state.submitting = true;
        }).addCase(resetPasswordSecondStep.fulfilled, (state) => {
            state.submitting = false;
            state.step = 2;
        }).addCase(resetPasswordSecondStep.rejected, (state, action) => {
            state.submitting = false;
            state.error = action.payload;
        });

        // reset password third step
        builder.addCase(resetPasswordThirdStep.pending, (state) => {
            state.submitting = true;
        }).addCase(resetPasswordThirdStep.fulfilled, (state) => {
            state.submitting = false;
            state.resetPasswordSuccess = true;
        }).addCase(resetPasswordThirdStep.rejected, (state, action) => {
            state.submitting = false;
            state.error = action.payload;
        });

        // reset password resend otp
        builder.addCase(resetPasswordResendOtp.pending, (state) => {
            state.submitting = true;
        }).addCase(resetPasswordResendOtp.fulfilled, (state) => {
            state.submitting = false;
            state.resendMessageSuccess = 'The new code was sent to your email address!';
        }).addCase(resetPasswordResendOtp.rejected, (state, action) => {
            state.submitting = false;
            state.error = action.payload;
        });
    },
});

export const {
    clearResetPasswordErrorState,
    clearResetPasswordSuccessState,
    clearResetPasswordResendMessageSuccess,
} = resetPasswordSlice.actions;
export default resetPasswordSlice.reducer;
