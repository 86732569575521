import { useEffect } from 'react';
import { SearchIcon } from '../../assets/icons';

function SearchModalButton() {
    // Search modal
    useEffect(() => {
        // Function to open the modal
        const openModal = () => {
            document.body.classList.add('search-modal-opened');
        };
    
        // Function to close the modal
        const closeModal = () => {
            document.body.classList.remove('search-modal-opened');
        };
    
        // Attach event listeners to all buttons with the class 'search-button'
        const searchButtons = document.querySelectorAll('.search-button');
        searchButtons.forEach(button => {
            button.addEventListener('click', openModal);
        });
    
        // Event listener for closing the modal
        const closeModalButton = document.getElementById('closeModal');
        if (closeModalButton) {
            closeModalButton.addEventListener('click', closeModal);
        }
    
        // Cleanup function
        return () => {
            searchButtons.forEach(button => {
                button.removeEventListener('click', openModal);
            });
    
            if (closeModalButton) {
                closeModalButton.removeEventListener('click', closeModal);
            }
        };
    }, []);

    return (
        <div className="user-access search search-button">
            <SearchIcon />
            <span>Search</span>
        </div>
    );
};

export default SearchModalButton;
